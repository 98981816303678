import {
  ComponentProps,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { ButtonVariant } from 'components/Button/Button.types';
import { LoadingDots } from 'components/LoadingDots/LoadingDots';

type Props = {
  children?: ReactNode;
  qaId?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  submitting?: boolean;
  submitDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  tabIndex?: number;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  icon?: ComponentProps<typeof Button>['icon'];
  iconPosition?: ComponentProps<typeof Button>['iconPosition'];
};

export function SubmitBaseButton({
  children,
  qaId,
  disabled,
  onClick,
  submitting,
  submitDisabled,
  size,
  type,
  variant,
  ...props
}: Props) {
  const [clicked, setClicked] = useState(false);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) {
      onClick(e);
    }

    setClicked(true);
  };

  useEffect(() => {
    if (!submitting) {
      setClicked(false);
    }
  }, [submitting]);

  return (
    <Button
      type={type || 'submit'}
      data-qa-id={qaId}
      onClick={handleClick}
      disabled={submitting || disabled || submitDisabled}
      size={size}
      data-qa-submitting={submitting === true}
      variant={variant}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Box position="relative">
        <Box
          visibility={submitting && clicked ? 'hidden' : undefined}
          display="inline-flex"
          alignItems="center"
        >
          {children}
        </Box>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          {submitting && clicked && (
            <LoadingDots
              size={size === 'large' ? 'small' : 'micro'}
              label="Submitting"
            />
          )}
        </Box>
      </Box>
    </Button>
  );
}
