import { ComponentProps, MouseEventHandler, ReactNode } from 'react';

import { ButtonVariant } from 'components/Button/Button.types';
import { useFormContext } from 'components/Form/FormContext';

import { SubmitBaseButton } from './SubmitBaseButton';
import { SubmitButtonBaseLink } from './SubmitButtonBaseLink';

type Props = {
  'data-qa-id'?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
  submitting?: boolean;
  children?: ReactNode;
  onClick?: MouseEventHandler;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'reset' | 'button';
  tabIndex?: number;
  variant?: ButtonVariant;
  icon?: ComponentProps<typeof SubmitBaseButton>['icon'];
  iconPosition?: ComponentProps<typeof SubmitBaseButton>['iconPosition'];
};

export function SubmitButton({
  'data-qa-id': qaId,
  isSubmitting,
  ...props
}: Props) {
  const { fieldQaIdPrefix, submitting, submitDisabled } = useFormContext();

  return (
    <SubmitBaseButton
      qaId={
        qaId ||
        (fieldQaIdPrefix ? `${fieldQaIdPrefix}-submit-button` : 'submit-button')
      }
      submitting={submitting || isSubmitting}
      submitDisabled={submitDisabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export function SubmitLink({
  'data-qa-id': qaId,
  ...props
}: {
  children: ReactNode;
  'data-qa-id'?: string;
  onClick: () => void;
}) {
  const { fieldQaIdPrefix, submitting, submitDisabled } = useFormContext();

  return (
    <SubmitButtonBaseLink
      qaId={fieldQaIdPrefix && qaId ? `${fieldQaIdPrefix}-${qaId}` : qaId}
      submitting={submitting}
      submitDisabled={submitDisabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
